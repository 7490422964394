<template>
    <div>
        <div class="chart" ref="chart" :style="{ width: '100%', height: height }"></div>
    </div>
</template>

<script>

export default {
    name: 'pieEcharts',

    data() {
        return {
            chart: null
        };
    },
    props: {
        height: {
            type: String,
            default: '10rem'
        },
        dataList: {
            type: Array
        }
    },
    mounted() {
        this.echarts()
    },

    methods: {
        nowSize(val, initWidth = 1920) {
            //当前视口宽度
            let nowClientWidth = document.documentElement.clientWidth;
            return val * (nowClientWidth / initWidth);
        },
        echarts() {
            this.chart = this.$echarts.init(this.$refs.chart); // 初始化echarts
            var option = {
                color: ['#9EDE39', '#F5B12E', '#3285DA'],
                series: [
                    {
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['50%', '50%'],
                        label: {
                            textStyle: {
                                color: '#fff', // 设置name的颜色

                            },
                            formatter: function (params) {
                                return params.data.name + ':' + params.data.value
                            },
                        },
                        labelLine: {
                            show: true,
                            fontSize: this.nowSize(10),
                        },
                        data: this.dataList
                    }
                ]
            };
            this.chart.setOption(option); // 设置对应配置项
            // 当窗口大小改变 echarts重置大小
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        }
    },
};
</script>

<style lang="scss" scoped></style>