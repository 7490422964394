<template>
    <div class="tableWrapper">
        <div class="table">
            <el-table height="100%" :data="TableDataSource" :row-key="rowKey"
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" border highlight-current-row
                ref="multipleTable" class="success table-container" stripe @sort-change="sortChange" v-loading="loading"
                @row-click="handleRowClick" @selection-change="handleSelectionChange" :cell-style="cellStyle"
                @cell-click="cellClick">
                <el-table-column type="selection" v-if="isChecked" :selectable="checkSelectable" width="55">
                </el-table-column>
                <el-table-column v-if="isNumber" label="序号" width="75px" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>

                <template v-for="(item, index) in tableColumn">
                    <el-table-column :key="index" :label="item.label" :prop="item.prop" :formatter="item.formatter"
                        v-if="item.checked && item.columnType && item.columnType == 'img'"
                        :width="item.width ? item.width : ''" sortable align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <img style="height: 100px" :src="scope.row[item.prop]" />
                        </template>
                    </el-table-column>

                    <el-table-column :key="index + 10000" :label="item.label" :prop="item.prop"
                        :formatter="item.formatter" v-if="item.checked && !item.columnType" sortable
                        :width="item.width ? item.width : ''" :align="item.align ? item.align : 'center'"
                        show-overflow-tooltip>
                    </el-table-column>
                </template>
                <!-- 轨迹溯源列 具名插槽、作用域插槽配合  s-->
                <template v-if="columnRoots">
                    <el-table-column label="轨迹溯源" align="center">
                        <template slot-scope="scope">
                            <slot :singlerow="scope.row" name="RootsColumn"></slot>
                        </template>
                    </el-table-column>
                </template>
                <!-- 轨迹溯源列 e-->
                <!-- 操作列 具名插槽、作用域插槽配合  s-->
                <template v-if="columnEdit">
                    <el-table-column label="操作" align="center" :width="widthClick" fixed="right">
                        <template slot-scope="scope">
                            <slot :singlerow="scope.row" name="EditColumn"></slot>
                        </template>
                    </el-table-column>
                </template>
                <!-- 操作列 e-->
            </el-table>
            <div class="pagination" v-if="isShow">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-size="size" :layout="computedLayout"
                    :total="total"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "hltable",
    components: {},
    props: {
        // 分页器是否只显示总数
        onlyShowTotal: {
            type: Boolean,
            default: false
        },
        subsidy: {
            type: String,
            default: ''
        },
        //树形表格必填项,选用唯一值作为参数
        rowKey: {
            type: String,
            required: false,
            default: "id",
        },
        //查询条件参数
        formSearch: {
            type: Object,
            required: false,
            default: null,
        },
        //接口访问路径(不需要请求加载数据的时候赋空)：/api/
        apiurl: {
            type: String,
            required: false,
        },
        //静态数据
        staticData: {
            type: Array,
            required: false,
        },
        //eltable的列配置，如下方注释
        // [{
        //   label: "label",
        //   prop: "Type",
        //   checked: true,
        //   formatter: function (row, column, cellValue, index) {
        //   }
        // }]
        tableColumn: {
            type: Array,
            required: true,
        },
        //控制表格刷新的标示
        refreshFlag: {
            type: String,
            required: false,
            default: "",
        },
        //是否添加溯源动态列
        columnRoots: {
            type: Boolean,
            default: false,
        },
        //是否添加动态列
        columnEdit: {
            type: Boolean,
            default: false,
        },
        //是否显示序号
        isNumber: {
            type: Boolean,
            default: false,
        },
        //是否开启单机选中行
        isClickSelect: {
            type: Boolean,
            default: true,
        },
        //是否显示checkbox
        isChecked: {
            type: Boolean,
            default: true,
        },
        //是否分页
        isPage: {
            type: Boolean,
            default: true,
        },
        //是否自动刷新
        // firstInit: {
        //   type: Boolean,
        //   default: true,
        // },
        // 分页是否显示
        isShow: {
            type: Boolean,
            default: true,
        },
        // 操作列宽
        widthClick: {
            type: String,
            default: "200%",
        },
        // 单元格样式函数
        handleCellStyle: {
            type: Function,
            default: () => { },
        }
    },
    data: function () {
        return {
            currentPage: 1,
            size: 20,
            tableRows: [],
            TableDataSource: [],
            loading: false,
            total: undefined,
            defaultLayout: 'total, sizes, jumper, ->, prev, pager, next'
        };
    },
    watch: {
        refreshFlag: {
            handler: function (val) {
                val && this.getTableDataSource();
            },
            deep: true,
            immediate: true,
        },
    },
    created() { },
    mounted() {
        this.getTableDataSource();
    },
    computed: {
        // 根据onlyShowTotal的值动态计算layout  
        computedLayout() {
            if (this.onlyShowTotal) {
                return 'total';
            }
            return this.defaultLayout;
        }
    },
    destroyed() { },
    methods: {
        //该方法写死的,后期改为方法注入
        checkSelectable(row) {
            if (row.url) {
                return row.refundStatus == 0;
            } else {
                return 1;
            }
        },
        getTableDataSource() {
            this.loading = true;
            let searchparm = {};
            const form = Object.assign({}, this.formSearch);
            if (this.isPage) {
                searchparm.query = form;
                searchparm.currentPage = this.currentPage;
                searchparm.size = this.size;
            } else {
                searchparm = form;
            }
            if (this.apiurl) {
                this.$store
                    .dispatch(this.apiurl, searchparm)
                    .then((response) => {
                        this.loading = false;
                        if (response.success) {
                            if (this.isPage) {
                                this.size = response.result.size;
                                this.total = response.result.total;
                                this.currentPage = response.result.currentPage;
                                this.TableDataSource = response.result.rows;
                            } else {
                                this.size = response.size ? response.size : this.size;
                                this.total = response.total
                                    ? response.total
                                    : response.result.length;
                                // response.result.rows.forEach((item) => {
                                //   item.fillIn = this.$timestampTostr(item.fillIn)
                                // })
                                this.TableDataSource = response.result;
                            }
                        } else {
                            this.$message.error(response.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false;
                    });
            } else {
                this.loading = false;
                this.size = searchparm.size;
                this.total = this.staticData.length;
                this.TableDataSource = this.staticData;
            }
        },
        refresh() {
            this.currentPage = 1;
        },
        handleRowClick(row) {
            if (this.isClickSelect) {
                this.$refs.multipleTable.toggleRowSelection(row);
            }
            if (this.subsidy == 'subsidy') {
                this.$emit('tableClick', row)
            }
        },
        cellClick(row, column, cell, event) {
            this.$emit('cellClick', row, column, cell, event)
        },
        handleSelectionChange: function (val) {
            this.tableRows = val;
            this.$emit("getSelectedRows", this.tableRows);
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.$emit("handCurrentPage", this.currentPage);
            this.getTableDataSource();
        },
        handleSizeChange(size) {
            this.size = size;
            this.$emit("handSize", this.size);
            this.getTableDataSource();
        },
        sortChange(prop) {
            console.log(prop);
        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            return this.handleCellStyle(row, column)
        }
    },
};
</script>

<style lang="scss" scoped>
.tableWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
}

.table {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.pagination {
    margin-top: 10px;
}

.table-container {
    border: 0.5px solid #ede8e8;
    box-shadow: 1px 1px 1px #ede8e8;
    border-radius: 5px;
}
</style>