import * as sysModule from '@/api/sysModule'
const actions = {
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.saveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  moduleTree({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.moduleTree(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.deleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  livingProjectdeleteData({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.livingProjectdeleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  livingProjectgetListByContract({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.livingProjectgetListByContract(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getListByLevel({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.getListByLevel(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subsidyStandardsaveData({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.subsidyStandardsaveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subsidyStandarddeleteData({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.subsidyStandarddeleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDataTree({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.getDataTree(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  areagetTree({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.areagetTree(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetCapitalgetDataList({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.buffetCapitalgetDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetCapitalgetcollect({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.buffetCapitalgetcollect(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  actions
}