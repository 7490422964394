import * as fws from '@/api/fws'
const actions = {
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
        fws.getDataList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
        fws.deleteData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
        fws.saveData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  orderServiceGetDataList({}, parm) {
    return new Promise((resolve, reject) => {
        fws.orderServiceGetDataList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  orderServicesaveData({}, parm) {
    return new Promise((resolve, reject) => {
        fws.orderServicesaveData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  doDownLoad({}, parm) {
    return new Promise((resolve, reject) => {
        fws.doDownLoad(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  }}
export default {
  namespaced: true,
  actions
}