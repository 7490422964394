<template>
    <div class="community">
        <div class="title">
            社区养老
        </div>
        <div class="zs" style="height:calc(15% -  1.74rem);padding:3px">
            <div class="zsbox" v-for="(item, index) in  zsList " :key="index">
                <div class="zsnum" :style="{
                    'background-image': 'url(' + item.bg + ')',
                    'background-repeat': 'no-repeat',
                }" style="width: 4.75rem;
height: 4rem;; background-size: 100% 100%">
                    <div class="zsnum2" :style="{ color: item.color }">
                        {{ item.num }}
                    </div>
                    <div class="zsword" :style="{ color: item.color }">
                        {{ item.name }}
                    </div>
                </div>
                <div class=" zstitle">
                    {{ item.title }}
                </div>
            </div>
        </div>
        <div class="fwcs" style="height:22%;">
            <titleWord :word="word1"></titleWord>
            <el-select v-model="valueStation1" placeholder="请选择站点">
                <el-option v-for="item in station1" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <lines></lines>
        </div>
        <div class="lxzb" style="height:22%;">
            <titleWord :word="word2"></titleWord>
            <el-select v-model="valueStation2" placeholder="请选择站点">
                <el-option v-for="item in station2" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <pie3 :dataList=dataList :allNum=allNum></pie3>
        </div>
        <div class="top5" style="height:21%;">
            <titleWord :word="word3"></titleWord>
            <columnarBar></columnarBar>
        </div>
        <div class="zxhd" style="height:18%;">
            <titleWord :word="word4"></titleWord>
            <div class="rzlContent">
                <checkHd></checkHd>
            </div>
        </div>
    </div>
</template>

<script>
import { titleWord } from "@/components";
import lines from '../echarts/line.vue'
import pie3 from '../echarts/pie3.vue'
import columnarBar from '../echarts/columnarBar.vue'
import checkHd from '../list/checkHd.vue'
export default {
    name: 'community',
    components: { titleWord, lines, pie3, columnarBar, checkHd },
    data() {
        return {
            word1: '站点助老服务人次',
            word2: '助老服务类型占比',
            word3: '助老服务Top5',
            word4: '最新活动',
            zsList: [
                {
                    name: '万件',
                    num: '14.6',
                    title: '老人档案数',
                    bg: require("@/assets/content/cs3.pic.jpg"),
                    color: '#00fbff'
                },
                {
                    name: '家',
                    num: '298',
                    title: '社区养老服务设施',
                    bg: require("@/assets/content/cs4.pic.jpg"),
                    color: '#FFA800'
                },
                {
                    name: '次',
                    num: '125',
                    title: '社区活动数',
                    bg: require("@/assets/content/cs2.pic.jpg"),
                    color: '#49DC80'
                },
                {
                    name: '人',
                    num: '53',
                    title: '活动参与人次',
                    bg: require("@/assets/content/cs1.pic.jpg"),
                    color: '#FF6D41'
                },
            ],
            station1: [],
            valueStation1: '',
            station2: [],
            valueStation2: '',
            dataList: [
                {
                    value: 7385,
                    name: '助餐'
                },
                {
                    value: 4,
                    name: '助聊'
                },
                {
                    value: 6,
                    name: '助购'
                },
                {
                    value: 3,
                    name: '助行'
                },
                {
                    value: 3,
                    name: '助急'
                },
                {
                    value: 5,
                    name: '助学'
                },
                {
                    value: 3,
                    name: '助洁'
                },
                {
                    value: 11,
                    name: '助医'
                },
                {
                    value: 21,
                    name: '助乐'
                },
                {
                    value: 2,
                    name: '助浴'
                },
            ],
            allNum: 0
        };
    },

    mounted() {
        for (let i = 0; i < this.dataList.length; i++) {
            this.allNum += this.dataList[i].value
        }
        console.log(this.allNum);
    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.community {
    height: calc(100% - 26px);
    width: calc(100% - 26px);
    background: url('~@/assets/content/box_bg1.png');
    background-size: 100% 100%;
    padding: 13px;
    z-index: 99;

    .title {
        height: 1.74rem;
        width: 20.43rem;
        background: url('~@/assets/content/titleB.png');
        background-size: 100% 100%;
        font-size: 1rem;
        line-height: 1.74rem;
        font-family: DOUYU, DOUYU-常规体;
        font-weight: normal;
        text-align: left;
        color: #ffffff;
        padding-left: 13px;
    }

    .zs {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .zsbox {
            display: flex;
            flex-direction: column;
            align-items: center;

            .zsnum {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                font-family: DOUYU, DOUYU-常规体;
                font-weight: normal;
                text-align: center;
            }

            .zstitle {
                font-size: 1.1rem;
                font-family: Source Han Sans CN, Source Han Sans CN-Regular;
                font-weight: 400;
                text-align: center;
                color: #ffffff;
                line-height: 1.56rem;
                zoom: 0.8;
            }
        }
    }

    .rzlContent {
        height: calc(100% - 1.43rem);
        width: 100%;
        padding: 5px;
    }

    /deep/ .el-input__inner {
        color: #fff;
        background: rgba(0, 113, 113, 0.20);
        border: 0.06rem solid #00a8a8;
        font-size: 1.25rem;
        font-family: DOUYU, DOUYU-常规体;
        font-weight: normal;
        text-align: center;
        color: #ffffff;
        line-height: 1.56rem;
        border: 0px;
        height: 2rem;
    }

    /deep/ .el-select {
        width: 100%;

    }
}
</style>