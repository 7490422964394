<template>
    <!-- 封装弹框 -->
    <div class="popup">
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :width="popupWidth" :before-close="handleClose"
            :modal-append-to-body="false">
            <slot>
            </slot>
            <span slot="footer" class="dialog-footer" v-if="buttonIshow">
                <el-button type="primary" @click="Save">{{ saveName }}</el-button>
                <el-button @click="Cancel">{{ cancelName }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    props: {
        dialogTitle: {
            type: String,
            default: '标题'
        },
        visible: {
            type: Boolean,
            default: false
        },
        popupWidth: {
            type: String,
            default: '550px'
        },
        saveName: {
            type: String,
            default: '保存'
        },
        cancelName: {
            type: String,
            default: '取消'
        },
        buttonIshow: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible
            },
            set(val) {
                // 当visible改变的时候，触发父组件的 updateVisible方法，在该方法中更改传入子组件的 centerDialogVisible的值
                this.$emit('updateVisible', val)
            }
        }
    },
    methods: {
        Cancel() {
            this.$emit('resetPopupData')
        },
        Save() {
            this.$emit('submitPopupData')
        },
        handleClose() {
            this.$emit('handleClose')
        }
    }
}
</script>
  
<style lang="scss" scoped>
.popup {

    /deep/ .el-dialog {
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(17, 130, 251, 0.5);
        border-radius: 5px;
    }

    .el-dialog__header {
        width: 100%;
        border-bottom: 1px solid #f6f7f9;
        box-sizing: border-box;
        padding: 0 20px;
        font-size: 16px;
    }

    .el-dialog__close.el-icon.el-icon-close {
        font-size: 20px;
        width: 17px;
        height: 17px;
    }

    .el-button.el-button--default {
        width: 88px;
        color: #1182fb;
        border: 1px solid #1182fb;
    }

    .el-button.el-button--primary {
        width: 88px;
    }

    /deep/ .el-dialog__body {
        padding: 24px 32px;
        box-sizing: border-box;
        height: 70vh !important;
        overflow-y: scroll
    }

    .el-dialog__headerbtn {
        top: 4px;
    }

    .el-dialog__title {
        color: #3c4354;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        line-height: 16px;
    }

    /deep/ .el-dialog__footer {
        text-align: center;
        display: flex;
        justify-content: center;
    }
}
</style>
  