<template>
  <div class="count-flop" :key="compKey">
    <div :class="item != '.' ? 'count-flop-box' : 'count-flop-point'" v-for="(item, index) in value" :key="index">
      <div v-if="item != '.'" class="count-flop-content" :class="index == value.length - 1 //判断只让最后一个数字有动画效果
        ? ['rolling_' + item]
        : ['rollingStatic_' + item]
        ">
        <div v-for="(item2, index2) in numberList" :key="index2" class="count-flop-num">
          {{ item2 }}
        </div>
      </div>
      <div v-else class="count-flop-content">.</div>
    </div>
    <div v-if="suffix" class="count-flop-unit">{{ suffix }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: [],
      numberList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      compKey: 0,
    }
  },
  props: {
    val: {
      type: String,
    },
    suffix: {
      type: String,
    },
  },
  watch: {
    val() {
      this.value = this.val.toString().split('')
      this.compKey += 1
    },
  },
  mounted() {
    this.value = this.val.toString().split('')
  },
  methods: {},
}
</script>
<style scoped>
.count-flop {
  display: inline-block;
  font-size: 0;
  /* 可更改 */
  height: 3.4rem;
  line-height: 3.4rem;
  font-size: 2.25rem;
  font-family: My Puma, My Puma-Regular;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.count-flop>div {
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 3.4rem;
  width: 3rem;
  line-height: 3.4rem;
  background: url('~@/assets/content/zs2.pic.jpg');
  background-size: 100% 100%;
}

.count-flop-box {
  /* 可更改 */
  /* margin-right: 0.1rem; */
  width: 1.7rem;
  /* border: 0.2rem solid rgba(72, 152, 241, 0.3); */
  line-height: 3.1rem;
  border-radius: 0.37rem;
}

.count-flop-point {
  /* 可更改 */
  margin-right: 0.3rem;
  width: 0.62rem;
}

.count-flop-content {
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  animation-fill-mode: forwards !important;
}

.rolling_0 {
  animation: rolling_0 1s ease;
}

.rollingStatic_0 {
  transform: translateY(0);
}

@keyframes rolling_0 {
  from {
    transform: translateY(-90%);
  }

  to {
    transform: translateY(0);
  }
}

.rolling_1 {
  animation: rolling_1 1s ease;
}

.rollingStatic_1 {
  transform: translateY(-10%);
}

@keyframes rolling_1 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.rolling_2 {
  animation: rolling_2 1s ease;
}

.rollingStatic_2 {
  transform: translateY(-20%);
}

@keyframes rolling_2 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-20%);
  }
}

.rolling_3 {
  animation: rolling_3 1s ease;
}

.rollingStatic_3 {
  transform: translateY(-30%);
}

@keyframes rolling_3 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-30%);
  }
}

.rolling_4 {
  animation: rolling_4 1s ease;
}

.rollingStatic_4 {
  transform: translateY(-40%);
}

@keyframes rolling_4 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-40%);
  }
}

.rolling_5 {
  animation: rolling_5 1s ease;
}

.rollingStatic_5 {
  transform: translateY(-50%);
}

@keyframes rolling_5 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-50%);
  }
}

.rolling_6 {
  animation: rolling_6 1s ease;
}

.rollingStatic_6 {
  transform: translateY(-60%);
}

@keyframes rolling_6 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-60%);
  }
}

.rolling_7 {
  animation: rolling_7 1s ease;
}

.rollingStatic_7 {
  transform: translateY(-70%);
}

@keyframes rolling_7 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-70%);
  }
}

.rolling_8 {
  animation: rolling_8 1s ease;
}

@keyframes rolling_8 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-80%);
  }
}

.rollingStatic_8 {
  transform: translateY(-80%);
}

.rolling_9 {
  animation: rolling_9 1s ease;
}

.rollingStatic_9 {
  transform: translateY(-90%);
}

@keyframes rolling_9 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-90%);
  }
}
</style>
