const getters = { 
  cachedViews: state => state.tagsView.cachedViews,
  permission_routes: state => state.permission.routes,
  user: state => state.user,
  sidebar: state => state.app.sidebar,
  commentsName: state => state.fwzlbzhjs.commentsName,
  routerNum:state=>state.routerNum,
  webConfig: state => state.webConfig,
  systemSettings: state => state.systemSettings,
  
}
export default getters
