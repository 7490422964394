<template>
    <div id="allmap" class="allmap" />
</template>
  
<script>
export default {
    data() {
        return {
            map: undefined
        }
    },
    mounted() {
        this.initMap()
    },
    methods: {
        initMap() {
            // 百度地图API功能
            // 创建Map实例
            var map = new BMap.Map('allmap', { enableMapClick: false })
            this.map = map
            // 初始化地图,设置中心点坐标和地图级别
            map.centerAndZoom(new BMap.Point(106.55, 29.57), 13)
            // 允许滚轮缩放
            map.enableScrollWheelZoom(true)
            // 禁止拖拽
            // map.disableDragging()
            // 允许拖拽
            // map.enableDragging()
            // 获取可视区域
            var bs = map.getBounds()
            // 可视区域左下角
            var bssw = bs.getSouthWest()
            // 可视区域右上角
            var bsne = bs.getNorthEast()
            console.log('当前地图可视范围是：' + bssw.lng + ',' + bssw.lat + '到' + bsne.lng + ',' + bsne.lat)
            // 添加平移和缩放按钮
            map.addControl(new BMap.NavigationControl({ anchor: BMAP_ANCHOR_TOP_RIGHT, type: BMAP_NAVIGATION_CONTROL_SMALL }))
            // 添加地图类型(2D图，混合图)
            var mapType1 = new BMap.MapTypeControl(
                {
                    mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP],
                    anchor: BMAP_ANCHOR_TOP_LEFT
                }
            )
            map.addControl(mapType1)
            // 添加定位控件
            var geolocationControl = new BMap.GeolocationControl()
            geolocationControl.addEventListener('locationSuccess', function (e) {
                console.log(e)
                // 定位成功事件
                var address = ''
                address += e.addressComponent.province
                address += e.addressComponent.city
                address += e.addressComponent.district
                address += e.addressComponent.street
                address += e.addressComponent.streetNumber
                console.log('当前定位地址为：' + address)
            })
            geolocationControl.addEventListener('locationError', function (e) {
                // 定位失败事件
                console.log(e.message)
            })
            map.addControl(geolocationControl)
            // 缩放事件(缩放完成后触发) 对应的有 zoomstart 拖拽前触发
            map.addEventListener('zoom', function () {
                console.log(map.getBounds())
                console.log(map.getBounds().getSouthWest())
            })
            // 拖拽事件(拖拽完成后触发) 对应的有 dragstart 拖拽前触发 dragging 拖拽中触发
            map.addEventListener('dragend', function () {
                console.log(map.getBounds())
                console.log(map.getBounds().getSouthWest())
            })
        },
        zoomend() {
        }
    }
}
</script>
  
<style scoped>
html,
body,
.allmap {
    width: 100%;
    height: 500px;
    overflow: hidden;
    margin: 0;
    font-family: "微软雅黑";
}
</style>
  
  