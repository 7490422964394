<template>
  <div class="sidebar-container" :style="{ width: !isCollapse ? theme.sideBarWidth : '55px' }">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeMenu" :unique-opened="true" :collapse-transition="true" :collapse="isCollapse"
        :background-color="theme.menuBg" :text-color="theme.menuText" :active-text-color="theme.subMenuActiveText"
        mode="vertical">
        <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import theme from '@/styles/theme.scss'
export default {
  components: { SidebarItem },
  data() {
    return {
      isCollapse: '',
      sidebar: this.$store.getters.sidebar,
    }
  },
  watch: {
    'sidebar.opened': {
      handler: function (v) {
        this.isCollapse = v
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['permission_routes']),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    theme() {
      return theme
    },
  },
  methods: {},
  mounted() { },
}
</script>
<style lang="scss" scoped></style>
