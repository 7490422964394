const state = {
  sidebar: {
    opened: false,
    form:{},
    datahands:'',
    ids:''
  }, 

}
const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
  },
  TOGGLE_FORM: (state,key) => {
    state.sidebar.form = key
  },
  TOGGLE_DAN: (state,key) => {
    state.sidebar.datahands = key
  },
  IDS: (state,key) => {
    state.sidebar.ids = key
  }
}
const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  toForm({ commit },param) {
    commit('TOGGLE_FORM',param)
  },
  todan({ commit },param) {
    commit('TOGGLE_DAN',param)
  },
  idsClick({ commit },param) {
    commit('IDS',param)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}