import * as area from '@/api/area'
const actions = {
    getDataTree({}, parm) {
    return new Promise((resolve, reject) => {
        area.getDataTree(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
}
export default {
  namespaced: true,
  actions
}