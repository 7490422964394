/*
 * @Descripttion: 
 * @version: 
 * @Author: wzh
 * @Date: 2022-08-16 09:35:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-08 15:42:03
 */
const mapGlobal = {

    //单调链 凸包算法
    monotoneChainConvexHull: function (points, options = {}) {
        function cw(p1, p2, p3) {
            return (p2[1] - p1[1]) * (p3[0] - p1[0]) - (p2[0] - p1[0]) * (p3[1] - p1[1]);
        }
        function byXThenY(point1, point2) {
            if (point1[0] === point2[0]) {
                return point1[1] - point2[1];
            }
            return point1[0] - point2[0];
        }

        if (!options.sorted) {
            points.sort(byXThenY);
        }

        const n = points.length;
        const result = new Array(n * 2);
        var k = 0;

        for (var i = 0; i < n; i++) {
            const point = points[i];
            while (k >= 2 && cw(result[k - 2], result[k - 1], point) <= 0) {
                k--;
            }
            result[k++] = point;
        }

        const t = k + 1;
        for (i = n - 2; i >= 0; i--) {
            const point = points[i];
            while (k >= t && cw(result[k - 2], result[k - 1], point) <= 0) {
                k--;
            }
            result[k++] = point;
        }

        return result.slice(0, k - 1);
    }

}
export default mapGlobal
