//底图类型
const baseLayerType = Object.freeze({
  TDTvector: Symbol("TDTvector"), //天地图
  TDTvector_blue: Symbol("TDTvector_blue"), //天地图
  TDTimage: Symbol("TDTimage"),
  NVvector: Symbol("NVvector"), //内网
  NVimage: Symbol("NVimage"),
});

//图层名称
const layerName = Object.freeze({
  UrbanPointLayer: "UrbanPointLayer",
  WaringPointLayer: "WaringPointLayer",
});

const mapExtent = Object.freeze({
  center: [118.30857743237303,36.82695377241685],
  zoom: 12,
  extent: [120.19828, 36.047244, 120.610494, 36.211888],
  padding: [150, 150, 150, 150],
});

export default {
  baseLayerType,
  layerName,
  mapExtent,
};
