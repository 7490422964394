import Map from "ol/Map";
import View from "ol/View";
import * as proj from "ol/proj";
import { Projection } from "ol/proj";
import Overlay from "ol/Overlay";
import { getCenter } from "ol/extent";
import { always, singleClick } from "ol/events/condition";
import { unByKey } from "ol/Observable";
import { getLength } from "ol/sphere";
import { getRenderPixel } from "ol/render";
import Feature from "ol/Feature";
import TileGrid from "ol/tilegrid/TileGrid";

import WFS from "ol/format/WFS";
import GeoJSON from "ol/format/GeoJSON";
import TopoJSON from "ol/format/TopoJSON";
import WKT from "ol/format/WKT";
import {
  and as andFilter,
  equalTo as equalToFilter,
  like as likeFilter,
  contains as containsFilter,
} from "ol/format/filter";

import VectorSource from "ol/source/Vector";
import TileJSONSource from "ol/source/TileJSON";
import WMTSSource from "ol/source/WMTS";
import XYZSource from "ol/source/XYZ";
import Cluster from "ol/source/Cluster";
import TileWMSSource from "ol/source/TileWMS";
import Stamen from "ol/source/Stamen";
import ImageWMSSource from "ol/source/ImageWMS";
import TileArcGISRest from "ol/source/TileArcGISRest";
import GeoTIFFSource from "ol/source/GeoTIFF.js";

import WMTSTileGrid from "ol/tilegrid/WMTS";

import Layer from 'ol/layer/Layer.js';
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import ImageLayer from "ol/layer/Image";
import WebGLTileLayer from "ol/layer/WebGLTile";

import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import Circle from "ol/style/Circle";
import Icon from "ol/style/Icon";
import RegularShape from "ol/style/RegularShape";

import InteractionSelect from "ol/interaction/Select";
import InteractionDragZoom from "ol/interaction/DragZoom";
import Draw from "ol/interaction/Draw";
import InteractionDragPan from "ol/interaction/DragPan";

import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import MultiPoint from "ol/geom/MultiPoint";
import MultiLineString from "ol/geom/MultiLineString";
import MultiPolygon from "ol/geom/MultiPolygon";
import * as olExtent from "ol/extent";
import * as olHas from 'ol/has';
import { defaults as controlDef } from "ol/control";
import { getVectorContext } from "ol/render.js";
import { altKeyOnly, click, pointerMove } from 'ol/events/condition';
// import {lineString} from 'turf/helpers/index'
// import {clone} from 'turf/clone/index'
// import {
//   lineString as TLineString,
//   point as TPoint,
//   lineSlice as TLineSlice,
//   polygon as TPolygon,
//   multiPolygon as TMultiPolygon,
//   booleanEqual as TBooleanEqual,
//   multiLineString as TMultiLineString,
//   multiPoint as TMultiPoint,
//   // clone as TClone,
//   // TCezierSpline,
//   // TBuffer,
//   // TLength,
//   booleanCrosses as TBooleanCrosses,
//   booleanOverlap as TBooleanOverlap,
//   booleanDisjoint as TBooleanDisjoint,
//   booleanWithin as TbooleanWithin,
//   booleanContains as TBooleanContains,
//   booleanPointInPolygon as TBooleanPointInPolygon,
// } from "turf/turf/turf";

// import turf from "turf/turf/turf";

export default {
  // openlayer地图脚本
  Map,
  View,
  proj,
  Projection,
  Overlay,
  getCenter,
  always,
  singleClick,
  unByKey,
  getLength,
  getRenderPixel,
  Feature,

  WFS,
  GeoJSON,
  TopoJSON,
  WKT,
  andFilter,
  equalToFilter,
  likeFilter,
  containsFilter,

  VectorSource,
  TileJSONSource,
  WMTSSource,
  XYZSource,
  Cluster,
  TileWMSSource,
  Stamen,
  ImageWMSSource,
  TileArcGISRest,
  GeoTIFFSource,

  WMTSTileGrid,

  Layer,
  TileLayer,
  VectorLayer,
  ImageLayer,
  WebGLTileLayer,

  Style,
  Stroke,
  Text,
  Fill,
  Circle,
  Icon,
  RegularShape,

  InteractionSelect,
  InteractionDragZoom,
  Draw,
  InteractionDragPan,

  Polygon,
  LineString,
  Point,
  MultiPoint,
  MultiLineString,
  MultiPolygon,

  TileGrid,
  controlDef,
  olExtent,
  getVectorContext,

  altKeyOnly,
  click,
  pointerMove,
  olHas,
  // TLineString,
  // TPoint,
  // TLineSlice,
  // TPolygon,
  // TMultiPolygon,
  // TBooleanEqual,
  // TMultiLineString,
  // TMultiPoint,
  // TBooleanCrosses,
  // TBooleanOverlap,
  // TBooleanDisjoint,
  // TbooleanWithin,
  // TBooleanContains,
  // TBooleanPointInPolygon,
};
