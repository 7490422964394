<template>
  <div class="tableWrapper">
    <div class="table">
      <el-table height="100%" :data="TableDataSource" :row-key="rowKey"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" border highlight-current-row
        ref="multipleTable" class="success table-container" stripe @sort-change="sortChange" v-loading="loading"
        @row-click="handleRowClick" @selection-change="handleSelectionChange" @cell-click="handleCellClick"
        @cell-mouse-enter="cellmouseenter">
        <el-table-column type="selection" v-if="isChecked" :selectable="checkSelectable" width="55">
        </el-table-column>
        <el-table-column v-if="isNumber" label="序号" width="75px" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <template v-for="(item, index) in tableColumn">
          <el-table-column :key="index" :label="item.label" :prop="item.prop" :formatter="item.formatter"
            show-overflow-tooltip v-if="item.checked && item.columnType && item.columnType == 'img'" sortable
            align="center">
            <template slot-scope="scope">
              <img style="height: 100px" :src="scope.row[item.prop]" />
            </template>
          </el-table-column>
          <el-table-column :label="item.label" :key="index" v-if="item.children" show-overflow-tooltip>
            <template v-for="(item2, index) in item.children">
              <el-table-column :key="index + 10000" :label="item2.label" :prop="item2.prop" :formatter="item2.formatter"
                v-if="item2.checked && !item2.columnType" sortable :width="item2.width ? item2.width : ''"
                :align="item2.align ? item2.align : 'center'">
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column :key="index + 10000" :label="item.label" :prop="item.prop" :formatter="item.formatter"
            show-overflow-tooltip v-if="item.checked && !item.columnType && !item.children" sortable
            :width="item.width ? item.width : ''" :align="item.align ? item.align : 'center'">
          </el-table-column>
        </template>
        <!-- 轨迹溯源列 具名插槽、作用域插槽配合  s-->
        <template v-if="columnRoots">
          <el-table-column label="轨迹溯源" align="center">
            <template slot-scope="scope">
              <slot :singlerow="scope.row" name="RootsColumn"></slot>
            </template>
          </el-table-column>
        </template>
        <!-- 轨迹溯源列 e-->
        <!-- 操作列 具名插槽、作用域插槽配合  s-->
        <template v-if="columnEdit">
          <el-table-column label="操作" align="center" :width="widthClick" fixed="right">
            <template slot-scope="scope">
              <slot :singlerow="scope.row" name="EditColumn"></slot>
            </template>
          </el-table-column>
        </template>
        <!-- 操作列 e-->
      </el-table>
      <div class="pagination" v-if="isShow">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-size="size" :layout="computedLayout" :total="total"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hltable",
  components: {},
  props: {
    // 分页器是否只显示总数
    onlyShowTotal: {  
      type: Boolean,  
      default: false  
    },
    //树形表格必填项,选用唯一值作为参数
    rowKey: {
      type: String,
      required: false,
      default: "id",
    },
    //查询条件参数
    formSearch: {
      type: Object,
      required: false,
      default: null,
    },
    //接口访问路径(不需要请求加载数据的时候赋空)：/api/
    apiurl: {
      type: String,
      required: false,
    },
    //静态数据
    staticData: {
      type: Array,
      required: false,
    },
    //eltable的列配置，如下方注释
    // [{
    //   label: "label",
    //   prop: "Type",
    //   checked: true,
    //   formatter: function (row, column, cellValue, index) {
    //   }
    // }]
    tableColumn: {
      type: Array,
      required: true,
    },
    supporterEntityList: {
      type: Array,
      required: true,
    },
    //控制表格刷新的标示
    refreshFlag: {
      type: String,
      required: false,
      default: "",
    },
    //是否添加溯源动态列
    columnRoots: {
      type: Boolean,
      default: false,
    },
    //是否添加动态列
    columnEdit: {
      type: Boolean,
      default: false,
    },
    //是否显示序号
    isNumber: {
      type: Boolean,
      default: false,
    },
    //是否开启单机选中行
    isClickSelect: {
      type: Boolean,
      default: true,
    },
    //是否显示checkbox
    isChecked: {
      type: Boolean,
      default: true,
    },
    //是否分页
    isPage: {
      type: Boolean,
      default: true,
    },
    //是否自动刷新
    // firstInit: {
    //   type: Boolean,
    //   default: true,
    // },
    // 分页是否显示
    isShow: {
      type: Boolean,
      default: true,
    },
    // 操作列宽
    widthClick: {
      type: String,
      default: "200%",
    },
    TableDataSource: {
      type: Array,
      required: true,
    }
  },
  data: function () {
    return {
      currentPage: 1,
      size: 20,
      tableRows: [],
      loading: false,
      total: undefined,
      defaultLayout: 'total, sizes, jumper, ->, prev, pager, next'
    };
  },
  created() { },
  mounted() {
    // this.getTableDataSource();
  },
  computed: {  
    // 根据onlyShowTotal的值动态计算layout  
    computedLayout() {  
      if (this.onlyShowTotal) {  
        return 'total';  
      }  
      return this.defaultLayout;  
    }  
  }, 
  destroyed() { },
  methods: {
    //该方法写死的,后期改为方法注入
    checkSelectable(row) {
      if (row.url) {
        return row.refundStatus == 0;
      } else {
        return 1;
      }
    },
    refresh() {
      this.currentPage = 1;
    },
    handleRowClick(row) {
      if (this.isClickSelect) {
        this.$refs.multipleTable.toggleRowSelection(row);
      }
    },
    handleSelectionChange: function (val) {
      this.tableRows = val;
      this.$emit("getSelectedRows", this.tableRows);
    },
    handleCellClick(row, column, cell, event) {
      this.$emit("handleCellClick", row, column, cell, event);
    },
    cellmouseenter(row, column, cell, event) {
      this.$emit("cellmouseenter", row, column, cell, event);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.$emit("handCurrentPage", this.currentPage);
      this.getTableDataSource();
    },
    handleSizeChange(size) {
      this.size = size;
      this.$emit("handSize", this.size);
      this.getTableDataSource();
    },
    sortChange(prop) {
      console.log(prop);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.table {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.pagination {
  margin-top: 10px;
}

.table-container {
  border: 0.5px solid #ede8e8;
  box-shadow: 1px 1px 1px #ede8e8;
  border-radius: 5px;
}
</style>
