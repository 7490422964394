<template>
    <div class="box">
        <div class="img"></div>
        <div class="word">{{ word }}</div>
    </div>
</template>

<script>
export default {
    name: 'titleWord',
    props: {
        word: {
            type: String
        }
    },
    data() {
        return {


        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.box {
    display: flex;
    align-items: center;
    padding-left: 7px;

    .img {
        width: 1.43rem;
        height: 1.43rem;
        background: url('~@/assets/content/zuTitle.png');
        background-size: 100% 100%;
    }

    .word {
        width: 100%;
        height: 1.43rem;
        font-size: 0.78rem;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        line-height: 1.43rem;
        color: #c3ffe1;
        border-bottom: 1px solid #2e6757
    }
}
</style>