var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"calc(100% - 1rem)","overflow":"hidden"}},[_c('vueSeamlessScroll',{staticClass:"seamless-warp",attrs:{"data":_vm.listData,"class-option":_vm.classOption}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"box"},[_c('div',{staticClass:"left",staticStyle:{"height":"100%","width":"1.13rem","background-size":"100% 100%"},style:({
                'background-image': 'url(' + item.ico + ')',
                'background-repeat': 'no-repeat',
            })}),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_c('div',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"dz"},[_c('div',{staticStyle:{"height":"1rem","width":"1rem","background-size":"100% 100%","margin-right":"0.5rem"},style:({
                        'background-image': 'url(' + item.icojg + ')',
                        'background-repeat': 'no-repeat',
                    })}),_vm._v(" "+_vm._s(item.jg)+" ")]),_c('div',{staticClass:"time"},[_c('div',{staticStyle:{"height":"1rem","width":"1rem","background-size":"100% 100%","margin-right":"0.5rem"},style:({
                        'background-image': 'url(' + item.icotime + ')',
                        'background-repeat': 'no-repeat',
                    })}),_vm._v(" "+_vm._s(item.time)+" ")])])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }