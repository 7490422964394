/*
 * @Descripttion: 
 * @version: 
 * @Author: wzh
 * @Date: 2022-07-04 11:09:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-11 14:54:16
 */
import mapApiComponent from "./globals/olApi.js"
import mapEnum from "./globals/mapEnum.js"
import mapGlobal from "./globals/mapGlobal.js"
const ComPanel = {
    install: function (Vue) {
        //地图Api
        Vue.prototype.mapApi = mapApiComponent;
        Vue.prototype.mapEnum = mapEnum;
        Vue.prototype.mapGlobal = mapGlobal;
        //公共组件api
        // Vue.prototype.comApi = comApiComponent;
        //Loading窗
        // Vue.component("Loading", comApiComponent.loading)       
    }
}
export default ComPanel   