export default {
  install(Vue) {
    /** 
     * 将时间戳转换成字符串日期
     */
    Vue.prototype.$timestampTostr = function (timestamp, format = "yyyy-mm-dd hh:mm:ss") {
      var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear()
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      var D = date.getDate() < 10 ? "0" + date.getDate() + ' ' : date.getDate() + ' ';
      var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      const dateJson = {
        "yyyy-mm-dd hh:mm:ss": Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s,
        "yyyy-mm-dd": Y + '-' + M + '-' + D,
        "yyyy/mm/dd": Y + '/' + M + '/' + D,
        "yyyymmdd": Y + M + D,
        "yyyy年mm月dd日": Y + '年' + M + '月' + D + '日',
        "mm月dd日": M + '月' + D + '日',
        "hh时": h + "时",
        "yyyy-mm-dd hh": Y + '-' + M + '-' + D + ' ' + h,
      }
      return dateJson[format]
    }
    /** 
     * 获取当前时间
     * format可自定以,作为输出的判别字段
     * day 默认为0,添加或减少对应的天数,举例:传递2当前日期加两天,传递-2当前日期减两天
     */
    Vue.prototype.$getNowDate = function (format, day = 0) {
      var date = new Date()
      var targetday_milliseconds = date.getTime() + 1000 * 60 * 60 * 24 * day;
      date.setTime(targetday_milliseconds); //注意，这行是关键代码
      var Y = date.getFullYear()
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      var D = date.getDate() < 10 ? "0" + date.getDate() + '' : date.getDate() + '';
      var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      const dateJson = {
        "yyyy-mm-dd hh:mm:ss": Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s,
        "yyyy-mm-dd": Y + '-' + M + '-' + D,
        "yyyy/mm/dd": Y + '/' + M + '/' + D,
        "yyyymmdd": Y + M + D,
        "yyyy年mm月dd日": Y + '年' + M + '月' + D + '日',
        "mm月dd日": M + '月' + D + '日',
        "hh时": h + "时",
        "yyyy-mm-dd hh": Y + '-' + M + '-' + D + ' ' + h,
      }
      return dateJson[format]
    }
    /**
     * /<summary>将度转换成为度分秒</summary>
     * @param {*} value 
     */
    Vue.prototype.$formatDegree = function (value) {
      value = Math.abs(value)
      var v1 = Math.floor(value) // 度
      var v2 = Math.floor((value - v1) * 60) // 分
      var v3 = Math.round(((value - v1) * 3600) % 60) // 秒
      return v1 + '°' + v2 + "'" + v3 + '"'
    }
    /**
     * <summary>将度分秒转换成为度</summary>
     * @param {*} value 
     */
    Vue.prototype.$formatRadius = function (value) {
      var v1 = value.split('°')[0]
      var v2 = value.split('°')[1].split("'")[0]
      var v3 = value.split('°')[1].split("'")[1].split('"')[0]
      var v4 = parseFloat(v2) / 60 + parseFloat(v3) / 3600
      return parseFloat(v1) + v4
    }
    /**
     *  查找出数组中特定值的json,并返回想要的值
     * 举例:返回数组中id为123的name值
     * @param list:数组
     * @param importKey:特定值的key值(id)
     * @param value:特定值(123)
     * @param exportKey:如果类型是string,返回key值(name),如果是传递{}则返回json
     */
    Vue.prototype.$getTypeName = function (list, importKey, value, exportKey) {
      try {
        if (typeof (exportKey) == "string") {
          return list.find(item => item[importKey] == value)[exportKey]
        } else {
          return list.find(item => item[importKey] == value)
        }
      } catch (error) {
        console.log(error)
      }
    }
    /**
     * js根据json数组中的某一个属性来给数据分组
     * 摘自https://www.cnblogs.com/newcapecjmc/p/12021449.html
     * 略加修改
     * @param arr:数组
     * @param keys:数组类型,依赖此第一个数组元素进行分组,其余的为附加值
     * @param addkeys:数组类型,作为附加值使用
     * @param groupkey:分组后的新的key
     */
    Vue.prototype.$arrayGroup = function (arr, key, groupkey = "data", addkeys = []) {
      try {
        var map = {},
          GroupResult = [];
        for (var i = 0; i < arr.length; i++) {
          var ai = arr[i];
          if (!map[ai[key]]) {
            const json = {}
            addkeys.forEach(element => {
              json[element] = ai[element]
            });
            json[key] = ai[key]
            json[groupkey] = [ai]
            GroupResult.push(json)
            map[ai[key]] = ai;
          } else {
            for (var j = 0; j < GroupResult.length; j++) {
              var dj = GroupResult[j];
              if (dj[key] == ai[key]) {
                dj[groupkey].push(ai);
                break;
              }
            }
          }
        }
        return GroupResult
      } catch (error) {
        console.log(error)
      }
    }
    /** 
     * JSON数组去重
     * @param: [array] json Array
     * @param: [string] 唯一的key名，根据此键名进行去重
     */
    Vue.prototype.$uniqueArray = function (array, key) {
        var result = [array[0]];
        for (var i = 1; i < array.length; i++) {
          var item = array[i];
          var repeat = false;
          for (var j = 0; j < result.length; j++) {
            if (item[key] == result[j][key]) {
              repeat = true;
              break;
            }
          }
          if (!repeat) {
            result.push(item);
          }
        }
        return result;
      },
      /** 
       * 深拷贝
       * @param: [array] json Array
       * @param: [string] 唯一的key名，根据此键名进行去重
       */
      Vue.prototype.$deepClone = function (target) {
        // 定义一个变量
        let result;
        // 如果当前需要深拷贝的是一个对象的话
        if (typeof target === 'object') {
          // 如果是一个数组的话
          if (Array.isArray(target)) {
            result = []; // 将result赋值为一个数组，并且执行遍历
            for (let i in target) {
              // 递归克隆数组中的每一项
              result.push(this.$deepClone(target[i]))
            }
            // 判断如果当前的值是null的话；直接赋值为null
          } else if (target === null) {
            result = null;
            // 判断如果当前的值是一个RegExp对象的话，直接赋值    
          } else if (target.constructor === RegExp) {
            result = target;
          } else {
            // 否则是普通对象，直接for in循环，递归赋值对象的所有值
            result = {};
            for (let i in target) {
              result[i] = this.$deepClone(target[i]);
            }
          }
          // 如果不是对象的话，就是基本数据类型，那么直接赋值
        } else {
          result = target;
        }
        // 返回最终结果
        return result
      },
      Vue.prototype.resetSetItem = function (key, newVal) {
        if (key === 'routrKey') {
          // 创建一个StorageEvent事件
          var newStorageEvent = document.createEvent('StorageEvent');
          const storage = {
            setItem: function (k, val) {
              sessionStorage.setItem(k, val);
      
              // 初始化创建的事件
              newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
      
              // 派发对象
              window.dispatchEvent(newStorageEvent)
            }
          }
          return storage.setItem(key, newVal);
        }
      }
  }
}