<template>
    <div>
        <div class="chart" ref="chart" :style="{ width: '8rem', height: height }"></div>
    </div>
</template>

<script>

export default {
    name: 'pieEcharts',

    data() {
        return {
            chart: null
        };
    },
    props: {
        height: {
            type: String,
            default: '8rem'
        },
        value: {
            type: String
        },
        color: {
            type: String
        },
        text: {
            type: String
        },
    },
    mounted() {
        this.echarts()
    },

    methods: {
        nowSize(val, initWidth = 1920) {
            //当前视口宽度
            let nowClientWidth = document.documentElement.clientWidth;
            return val * (nowClientWidth / initWidth);
        },
        echarts() {
            this.chart = this.$echarts.init(this.$refs.chart); // 初始化echarts
            const value = this.value;
            var bai = Number(value) / 29019 * 100
            console.log(9999999, bai)
            var option = {
                title: [
                    {
                        top: 0,
                        left: 0,
                        textStyle: {
                            fontWeight: "normal",
                            fontSize: 16,
                            color: "#fff",
                        },
                    },
                    {
                        text: "{a|" + value + "}",
                        show: true,
                        x: "center",
                        y: "25%",
                        textStyle: {
                            rich: {
                                a: {
                                    fontSize: this.nowSize(20),
                                    color: "#FFFFFF",
                                    fontWeight: "bold",
                                },
                            },
                        },
                    },
                    {
                        text: this.text,
                        x: "center",
                        y: "center",
                        textStyle: {
                            fontWeight: "normal",
                            fontSize: this.nowSize(13),
                            color: "#7ee4f5",
                        },
                    },
                ],
                polar: {
                    center: ["50%", "50%"],
                    radius: ["60%", "75%"],
                },
                angleAxis: {
                    max: 100,
                    show: false,
                },
                radiusAxis: {
                    type: "category",
                    show: true,
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                series: [
                    {
                        data: [bai],
                        name: "",
                        type: "bar",
                        roundCap: true,
                        showBackground: true,
                        backgroundStyle: {
                            color: "rgba(19, 84, 146, .4)",
                        },
                        coordinateSystem: "polar",
                        itemStyle: {
                            normal: {
                                color: this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: this.color,
                                    },
                                    {
                                        offset: 1,
                                        color: this.color,
                                    },
                                ]),
                            },
                        },
                    },
                ],
            }
            this.chart.setOption(option); // 设置对应配置项
            // 当窗口大小改变 echarts重置大小
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        }
    },
};
</script>

<style lang="scss" scoped></style>