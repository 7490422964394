const state = {
    routerList:[
    {
        name:'监管中心',
        check:true,
        key:'jgzx',
        children:[{
            name:'居家养老服务监管系统',
            check:true,
            key:'jjyl',
            children:[
                {
                    name:'老人信息管理',
                    key:'/LRXXGL'
                },
                {
                    name:'服务商管理',
                    key:'/FWSGL'
                },
                {
                    name:'居家服务工单',
                    key:'/JJFWGD'
                },
            ]
        },
    ]      
    },
    {
        name:'政府监管',
        check:true,
        key:'zfjg',
        children:[{
            name:'政府监管',
            check:true,
            key:'zfjg',
            children:[
                {
                    name:'监管人员',
                    key:'/JGRY'
                },
                {
                    name:'日常监管',
                    key:'/RCJG'
                },
                {
                    name:'填报完成',
                    key:'/SJTB'
                },
            ]
        },
        {
            name:'居家社区监管',
            check:true,
            key:'jjsqjg',
            children:[
                {
                    name:'居家服务监管',
                    key:'/JJFWJG'
                },
                {
                    name:'考核评优',
                    key:'/KHPY'
                },
                {
                    name:'老年人探访关爱',
                    key:'/LNRTFGA'
                },
            ]
            
        },
        {
            name:'机构监管',
            check:true,
            key:'jgjg',
            children:[
                {
                    name:'服务质量标准化',
                    key:'/FWZLBZH'
                },
                {
                    name:'安全监督管理记录',
                    key:'/AQJDGLJL'
                },
                {
                    name:'食品安全',
                    key:'/SPAQ'
                },
                {
                    name:'星级评定',
                    key:'/XJPD'
                }
            ]
        },
        {
            name:'客流视频监控',
            check:true,
            key:'klspjk',
            children:[
                {
                    name:'机构视频监控',
                    key:'/VIDEO'
                }
            ]
        },
    ]      
    },
    {
        name:'大屏数据',
        check:true,
        key:'dpsj',
        children:[{
            name:'大屏数据',
            check:true,
            key:'dpsj',
            children:[
                {
                    name:'大屏数据',
                    key:'/DPSJ'
                }
            ]
        },
        ]
    },
    {
        name:'系统管理',
        check:true,
        key:'xtgl',
        children:[{
            name:'系统管理',
            check:true,
            key:'xtgl',
            children:[
                {
                    name:'系统管理',
                    key:'/system'
                },
                {
                    name:'个人设置',
                    key:'/home'
                }
            ]
        },
        ]
    }
] 
  }
  const mutations = {
    TOGGLE_SIDEBAR: (state, param) => {
        state.routerList = param
      }
  }
  const actions = {
    generateRoutes({
        commit
      }, moduleList) {
          commit('SET_ROUTES',moduleList)
      }
  }
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }